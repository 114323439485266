<template>
  <div
    v-if="document.id"
    class="d-flex flex-column flex-grow-1"
  >
    <document-toolbar
      :tab="tab"
      :new-documents="newDocuments"
      :files="document.files"
      :query-config="{dataSourceFolder: document.importDataSourceFolder}"
      :revert="refreshDocument"
      :save="saveDocument"
      :comment="true"
      :hasComment="!!(document.comment || '').length"
      show-file-manager
      readonly-file-manager
      v-on="$listeners"
      :links.sync="document.links"
      :show-link-manager="true"
      :showSave="!readonly"
      :showCancel="!readonly"
      :labels.sync="document.labels"
      show-labels
      @close-pop-up="refreshCache()"
      @update-links="refreshCache()"
    >
      <v-col
        cols="auto"
        class="ml-3"
      >
        <span class="secondary--text font-weight-bold">{{document.status}}</span>
      </v-col>
      <v-col
        cols="auto"
        class="ml-3"
      >
        <span v-if="document.balance && document.daysPastDue <= 0">{{-document.daysPastDue}} {{$t('t.DaysBeforeDate')}}</span>
        <span v-if="document.balance && document.daysPastDue > 0">{{document.daysPastDue}} {{$t('t.DaysPastDue')}}</span>
      </v-col>
      <v-col
        cols="auto"
        class="ml-3"
      >
        <account-ref
          v-if="document.accountId !== $route.params.id"
          :icon-props="{small: true}"
          :id="document.accountId"
          class="clickable secondary--text"
          @click.native.stop="$router.push('/accounts/' + document.accountId).catch(() => {})"
        />
      </v-col>
    </document-toolbar>
    <document-in-tab>
      <comment
        class="mb-6"
        :text.sync="document.comment"
        :showComment="tab.showComment"
        :disabled="readonly"
        :counter="2000"
        :rules="commentRules"
      />
      <!-- <v-row>
        <v-col>
          <v-card>
            <v-card-text> -->
      <v-row>
        <v-col
          cols=3
          class="pr-1"
        >
          <v-card class="full-height primary">
            <v-card-title class="pb-0">{{$t('t.Amount')}}</v-card-title>
            <v-card-text>
              <div class="font-weight-bold text-h5">{{document.balance|currency(document.currency)}}</div>
              <div>{{document.amount|currency(document.currency)}}</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols=3
          class="px-1"
        >
          <v-card class="full-height">
            <v-card-title class="mb-1 px-4 pt-4 pb-0">{{$tc('t.PaymentCount', paymentCount)}}</v-card-title>
            <v-card-text class="px-4">
              <div class="line-height">
                <payment-ref
                  v-if="lastPaymentId"
                  :id="lastPaymentId"
                  :options="{emphase: true}"
                />
                <span v-else>{{$t('t.None')}}</span>
              </div>
              <div
                v-if="otherPaymentIds.length"
                @click="showPaymentList"
                ref="payments"
                class="clickable wrapper"
              >
                +<span class="primary--text font-weight-bold">{{otherPaymentIds.length}}</span>
                {{$tc('t.PaymentCount', otherPaymentIds.length)|lowercase}}
                <v-menu
                  v-if="$refs.payments"
                  v-model="showPaymentListMenu"
                  :attach="$refs.payments"
                  nudge-left=16
                >
                  <v-card class="background-plain">
                    <v-card-text>
                      <div
                        class="d-flex justify-space-between clickable"
                        v-for="id in otherPaymentIds"
                        :key="id"
                      >
                        <payment-ref
                          :id="id"
                          :options="{emphase: true}"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="6"
          class="pl-1"
        >
          <v-card class="full-height">
            <v-card-title class="mb-1 px-4 pt-4 pb-0">{{$tc('t.EventCount', eventCount)}}</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  v-if="document.promises.ids.length > 0"
                >
                  <div
                    class="clickable"
                    @click="showPromiseList"
                    ref="promises"
                  >
                    <v-icon
                      x-small
                      class="mr-2"
                    >{{$icon('i.GoToDocument')}}</v-icon>
                    <span class="primary--text font-weight-bold">{{document.promises.amount|currency(document.currency)}}</span>
                    {{$t('t.In')|lowercase}}
                    <span class="primary--text font-weight-bold">{{document.promises.ids.length}} </span>
                    <span class="font-weight-bold">{{$tc('t.Promise', document.promises.ids.length)}}</span>
                    {{$t('t.At')|lowercase}}
                    <span class="primary--text font-weight-bold">{{document.promises.nextInstallmentDate|date}} </span>
                  </div>
                  <v-menu
                    v-model="showPromiseListMenu"
                    :attach="$refs.promises"
                    nudge-bottom=85
                    nudge-right=34
                  >
                    <v-card class="background-plain">
                      <v-card-text>
                        <div
                          class="d-flex justify-space-between clickable"
                          v-for="id in document.promises.ids"
                          :key="id"
                          @click="openPromise(id)"
                        >
                          <document-number-ref
                            :id="id"
                            :cache-type="typePromiseRef"
                          />
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  v-if="document.disputes.ids.length > 0"
                >
                  <div
                    class="clickable"
                    @click="showDisputeList"
                    ref="disputes"
                  >
                    <v-icon
                      x-small
                      class="mr-2"
                    >{{$icon('i.GoToDocument')}}</v-icon>
                    <span class="primary--text font-weight-bold">{{document.disputes.amount|currency(document.currency)}}</span>
                    {{$t('t.In')|lowercase}}
                    <span class="primary--text font-weight-bold">{{document.disputes.ids.length}} </span>
                    <span class="font-weight-bold">{{$tc('t.Dispute', document.disputes.ids.length)}}</span>
                    {{$t('t.At')|lowercase}}
                    <span class="primary--text font-weight-bold">{{document.disputes.nextTargetDate|date}} </span>
                  </div>
                  <v-menu
                    v-model="showDisputeListMenu"
                    :attach="$refs.disputes"
                    nudge-bottom=85
                    nudge-right=34
                  >
                    <v-card class="background-plain">
                      <v-card-text>
                        <div
                          class="d-flex justify-space-between clickable"
                          v-for="id in document.disputes.ids"
                          :key="id"
                          @click="openDispute(id)"
                        >
                          <dispute-ref
                            :id="id"
                            :options="{showDate: true}"
                          />
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  v-if="document.announcements.ids.length > 0"
                >
                  <div
                    class="clickable"
                    @click="showAnnouncementList"
                    ref="announcements"
                  >
                    <v-icon
                      x-small
                      class="mr-2"
                    >{{$icon('i.GoToDocument')}}</v-icon>
                    <span class="primary--text font-weight-bold">{{document.announcements.amount|currency(document.currency)}}</span>
                    {{$t('t.In')|lowercase}}
                    <span class="primary--text font-weight-bold">{{document.announcements.ids.length}} </span>
                    <span class="font-weight-bold">{{$tc('t.AllocationAnnouncement', document.announcements.ids.length)}}</span>
                    {{$t('t.At')|lowercase}}
                    <span class="primary--text font-weight-bold">{{document.announcements.nextTargetDate|date}} </span>
                  </div>
                  <v-menu
                    v-model="showAnnouncementListMenu"
                    :attach="$refs.announcements"
                    nudge-bottom=85
                    nudge-right=34
                  >
                    <v-card class="background-plain">
                      <v-card-text>
                        <div
                          class="d-flex justify-space-between clickable"
                          v-for="id in document.announcements.ids"
                          :key="id"
                          @click="openAnnouncement(id)"
                        >
                          <allocation-announcement-ref
                            :id="id"
                            :options="{showDate: true}"
                          />
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col cols=12>
                  <v-switch
                    :label="$t('t.DunningDisabled')"
                    hide-details
                    v-model="dunningDisabled"
                    :disabled="!$store.getters.currentUserHasPermission('CanChangeInvoiceState')"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols=12>
          <v-card>
            <v-card-title>{{$t('t.Details')}}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols=5>
                  <long-text-field
                    readonly
                    :label="$t('t.Description')"
                    placeholder=" "
                    :text="document.label"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    :label="$t('t.Date')"
                    :value="formatDate(document.date)"
                  >
                    <template slot="prepend-inner">
                      <v-icon>{{$icon('i.Calendar')}}</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    :label="$t('t.DueDate')"
                    :value="formatDate(document.dueDate)"
                  >
                    <template slot="prepend-inner">
                      <v-icon>{{$icon('i.Calendar')}}</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    :label="$t('t.PaymentTerms')"
                    placeholder=" "
                    :value="document.paymentTerms"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    :label="$t('t.PurchaseOrder')"
                    placeholder=" "
                    :value="document.purchaseOrder"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    readonly
                    :label="$t('t.EventDate')"
                    :value="document.eventDate|date"
                  />
                </v-col>
                <v-col>
                  <picker
                    :dense="false"
                    :label="$t('t.TransactionType')"
                    persistent-placeholder
                    :placeholder="$t('t.None')"
                    :pre-items="transactionTypeNone"
                    document-types="transaction-types"
                    :selected-id.sync="transactionTypeId"
                    sort
                    :disabled="!$store.getters.currentUserHasPermission('CanChangeTransactionType')"
                  />
                </v-col>
                <v-col>
                  <picker
                    :dense="false"
                    :clearable="false"
                    :label="$t('t.BusinessDivision')"
                    document-types="business-divisions"
                    :selected-id.sync="document.businessDivisionId"
                    disabled
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    :label="$t('t.PaymentMethod')"
                    placeholder=" "
                    :value="document.paymentMethod"
                  />
                </v-col>
                <v-col>
                  <div
                    ref="fees"
                    class="wrapper"
                  >
                    <v-menu
                      :attach="$refs.fees"
                      min-width="max-content"
                      left
                    >
                      <template v-slot:activator="{ on }">
                        <div>
                          <amount-field
                            readonly
                            :label="$t('t.Fees')"
                            :currency="document.currency"
                            :amount="document.fees"
                            :empty-value="0"
                            append-icon="mdi-dots-horizontal"
                            @click:append="on.click"
                          />
                        </div>
                      </template>
                      <v-card class="background-plain">
                        <v-card-text>
                          <div class="d-flex justify-space-between">
                            <span class="primary--text font-weight-bold">{{$t('t.Fees')}}</span><span class="ml-4">{{document.fees-document.lumpSum|currency(document.currency)}}</span>
                          </div>
                          <div class="d-flex justify-space-between">
                            <span class="primary--text font-weight-bold no-wrap">{{$t('t.LumpSum')}}</span><span class="ml-4">{{document.lumpSum|currency(document.currency)}}</span>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </v-col>
                <v-col>
                  <div
                    ref="provisions"
                    class="wrapper"
                  >
                    <v-menu
                      :attach="$refs.provisions"
                      min-width="max-content"
                      left
                    >
                      <template v-slot:activator="{ on }">
                        <div>
                          <amount-field
                            readonly
                            :label="$t('t.Provision')"
                            :currency="$store.getters.currentUser.systemCurrency"
                            :amount="document.provision"
                            :empty-value="0"
                            append-icon="mdi-dots-horizontal"
                            @click:append="on.click"
                          />
                        </div>
                      </template>
                      <v-card class="background-plain">
                        <v-card-text>
                          <div class="d-flex justify-space-between">
                            <span class="primary--text font-weight-bold">{{$t('t.Provision')}}</span><span class="ml-4">{{document.provision|currency($store.getters.currentUser.systemCurrency)}}</span>
                          </div>
                          <div class="d-flex justify-space-between">
                            <span class="primary--text font-weight-bold no-wrap">{{$t('t.ProvisionBase')}}</span><span class="ml-4">{{document.provisionBase|currency($store.getters.currentUser.systemCurrency)}}</span>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="document.externalStatus">
                <v-col>
                  <v-text-field
                    readonly
                    :label="$t('t.ExternalStatus')"
                    placeholder=" "
                    :value="document.externalStatus"
                  />
                </v-col>
                <v-col>
                  <v-textarea
                    :label="label || $t('t.Comments')"
                    v-model="document.externalStatusComment"
                    :rows="2"
                    readonly
                    no-resize
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    :label="$t('t.ExternalStatusDate')"
                    placeholder=" "
                    :value="formatDateTime(document.externalStatusDate)"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    :label="$t('t.DepositDate')"
                    placeholder=" "
                    :value="formatDate(document.externalDepositDate)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols=12>
          <v-form
            ref="form"
            @input="$emit('document-can-save', {tab: tab, value: $event})"
            :disabled="readonly"
          >
            <v-card v-if="document.customFields.length">
              <v-card-title>{{$t('t.CustomFields')}}</v-card-title>
              <v-card-text>
                <custom-fields-columns :custom-fields.sync="document.customFields" />
              </v-card-text>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </document-in-tab>
  </div>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'
import navigation from '@/navigationController'
import tabController from '@/tabController'
import customFieldsController from '@/customFieldsController'
import Search from '@/pages/search/controllers'

export default {
  mixins: [DocumentEditor],
  components: {
    AccountRef: () => import('@/components/documents-ref/account-ref'),
    AllocationAnnouncementRef: () => import('@/components/documents-ref/allocation-announcement-ref'),
    AmountField: () => import('@/components/amount-field'),
    CustomFieldsColumns: () => import('@/components/custom-fields-columns'),
    Comment: () => import('@/components/comment'),
    Picker: () => import('@/components/picker'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DisputeRef: () => import('@/components/documents-ref/dispute-ref'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    LongTextField: () => import('@/components/long-text-field'),
    PaymentRef: () => import('@/components/documents-ref/payment-ref.vue')
  },
  async created () {
    Object.assign(this.tab, { save: this.save, cancel: this.cancel })
  },
  data () {
    return {
      commentRules: [value => (value?.length ?? 0) <= 2000],
      showAnnouncementListMenu: false,
      showDisputeListMenu: false,
      showPaymentListMenu: false,
      showPromiseListMenu: false
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (this.document && this.document.number) {
        if (this.document.comment?.length) {
          this.$emit('document-comment', { tab: this.tab, value: true })
        }
        this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.document.number}` })
        this.$waitFor(() => this.$refs.form).then(form => setTimeout(form?.validate, 500))
      }
    },
    async save () {
      return new Search(Search.actions.SET_INVOICE_PROPERTIES)
        .chain(s => s.searchedIds.include([{ id: this.id, type: 'invoices' }]))
        .chain(s => s.searchedDocumentTypes.include(['invoices']))
        .executeWithJSON({ target: this.invoiceToJSON() })
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    },
    invoiceToJSON () {
      return Object.assign(
        {
          comment: this.document.comment,
          excludeFromDunning: this.document.excludeFromDunning,
          transactionTypeId: this.transactionTypeId
        },
        customFieldsController.convertForJSON(this.document.customFields))
    },
    openAnnouncement (id) {
      this.openDocument(id, 'allocation-announcements')
    },
    openDispute (id) {
      this.openDocument(id, 'disputes')
    },
    openDocument (id, type) {
      navigation.navigateTo(id, type)
    },
    openPromise (id) {
      this.openDocument(id, 'promises')
    },
    showAnnouncementList () {
      if (this.document.announcements.ids.length > 1) {
        if (!this.showAnnouncementListMenu) {
          this.$nextTick(() => {
            this.showAnnouncementListMenu = true
          })
        }
      } else if (this.document.announcements.ids.length > 0) {
        this.openAnnouncement(this.document.announcements.ids[0])
      }
    },
    showDisputeList () {
      if (this.document.disputes.ids.length > 1) {
        if (!this.showDisputeListMenu) {
          this.$nextTick(() => {
            this.showDisputeListMenu = true
          })
        }
      } else if (this.document.disputes.ids.length > 0) {
        this.openDispute(this.document.disputes.ids[0])
      }
    },
    showPaymentList () {
      if (!this.showPaymentListMenu) {
        this.$nextTick(() => {
          this.showPaymentListMenu = true
        })
      }
    },
    showPromiseList () {
      if (this.document.promises.ids.length > 1) {
        if (!this.showPromiseListMenu) {
          this.$nextTick(() => {
            this.showPromiseListMenu = true
          })
        }
      } else if (this.document.promises.ids.length > 0) {
        this.openPromise(this.document.promises.ids[0])
      }
    }
  },
  computed: {
    newDocuments () {
      const documents = []

      if (this.$store.getters.currentUserHasPermission('AccountNewDispute') && this.document.disputeAmountAllowed !== 0) {
        documents.push(Object.assign(tabController.disputesTab(), { invoices: [Object.assign({}, this.document)] }))
      }
      if (this.$store.getters.currentUserHasPermission('AccountNewPromise') && this.document.promises?.ids.length === 0 && this.document.promiseAmountAllowed !== 0) {
        documents.push(Object.assign(tabController.promisesTab(), { invoices: [Object.assign({}, this.document)] }))
      }

      if (this.document?.files?.length > 0) {
        documents.push(Object.assign(tabController.newMessageTab(), { invoiceIds: [this.document.id] }))
      }

      if (documents.length) {
        return documents
      } else {
        return null
      }
    },
    readonly () {
      return !this.$store.getters.currentUserHasPermission('InvoiceDetails')
    },
    dunningDisabled: {
      get () {
        return this.document.excludeFromDunning
      },
      set (v) {
        this.document.excludeFromDunning = v
      }
    },
    cacheType () {
      return DocumentEditor.CacheType.InvoiceDetail
    },
    eventCount () {
      return (this.document.promises?.ids.length ?? 0) + (this.document.disputes?.ids.length ?? 0) + (this.document.announcements?.ids.length ?? 0)
    },
    id () {
      return this.tab.documentId
    },
    lastPaymentId () {
      return this.document.paymentIds?.[0]
    },
    otherPaymentIds () {
      return this.document.paymentIds?.slice(1)
    },
    paymentCount () {
      return this.document.paymentIds?.length || 1
    },
    transactionTypeId: {
      get () {
        return this.document.transactionTypeId
      },
      set (v) {
        this.document.transactionTypeId = v
      }
    },
    transactionTypeNone () {
      return [{
        name: this.$t('t.None'),
        id: null,
        type: 'transaction-types'
      }]
    },
    typePromiseRef () {
      return DocumentEditor.CacheType.PromiseRef
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.line-height
  line-height 2rem

.full-height
  height 100%

.no-wrap
  white-space nowrap

.wrapper
  position relative
</style>
